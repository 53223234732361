@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "./styles/colors.scss";
@import "./styles/style.scss";

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "~weather-icons/css/weather-icons.min.css";
@import "~weather-icons/css/weather-icons-wind.min.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.min.css";
