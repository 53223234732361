$dark-blue: #22293f;
$light-pink: #db99ee;
$light-blue: #87d7ea;

$air-light: rgb(0, 196, 204);
$air-blue: rgb(27, 147, 200);
$air-dark: rgb(31, 37, 60);
$air-purple: rgb(124, 42, 232);

$body-color: $black;
$link-color: $black;

$card-bg: #fff;
$card-border-color: #f2f4f6;
$card-hover-color: rgba($white, 0.7);
$card-active-color: rgba($white, 0.9);

$navbar-light-color: rgba($white, 0.55);
$navbar-light-hover-color: rgba($white, 0.7);
$navbar-light-active-color: rgba($white, 0.9);
$navbar-light-disabled-color: rgba($white, 0.3);

$sidebar-bg-color: $air-dark;
$sidebar-border-color: $air-dark;
$sidebar-item-border-color: #f2f4f6;

$topbar-bg-color: transparent;
$topbar-border-color: #edeff2;

$nav-link-color: #495057;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-color: black;

$toast-background-color: rgba($white, 1);
$toast-header-background-color: rgba($white, 1);

$form-check-input-width: 2em;
$form-switch-width: 4em;

$custom-colors: (
	"dark-blue": $dark-blue,
	"light-pink": $light-pink,
	"light-blue": $light-blue,

	"air-light": $air-light,
	"air-blue": $air-blue,
	"air-dark": $air-dark,
	"air-purple": $air-purple,
);
